.howitworks-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.howitworks-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.howitworks-container h2 {
  color: #ff69b4; /* Bright pink text */
  margin-top: 20px;
}

.howitworks-container p {
  color: #555;
  line-height: 1.6;
}

.howitworks-container code {
  background-color: #f1f1f1;
  padding: 2px 4px;
  border-radius: 4px;
}
